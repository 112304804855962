import { sanitizeHTML } from "@/lib/utils";

interface ContentLeftDarkData {
  text?: {
    text?: string;
  };
}

interface ContentLeftDarkProps {
  data: ContentLeftDarkData;
}

export const fields = [
  "text.text"
] as const;

export default function ContentLeftDark({ data }: ContentLeftDarkProps) {
  return (
    <section
      id="ContentLeftDark"
      className="bg-[#232323] py-8 md:py-12 text-white"
    >
      <div 
        className="max-w-[1080px] mx-auto px-4 space-y-4 leading-relaxed prose prose-sm prose-invert"
        dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
      />
    </section>
  );
}
