import { sanitizeHTML } from "@/lib/utils";

interface ClientResultData {
  heading: {
    title: string;
  };
  video: {
    embed_url: string;
    embed_title: string;
  };
}

interface ClientResultProps {
  data: ClientResultData;
}

export const fields = [
  "heading.title",
  "video.embed_url",
  "video.embed_title",
] as const;

export default function ClientResult({ data }: ClientResultProps) {
  return (
    <section
      id="client-result"
      className="bg-black text-white pt-0 pb-[21px] mt-0 mb-0"
    >
      <div className="w-full max-w-[1080px] mx-auto pt-[75px] px-6">
        <div className="pb-0 pt-0 mt-0 mb-10">
          <h2
            className="font-freightBig text-[44px] text-white leading-[1.1em] text-center"
            dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || "")}
          />
        </div>
        <div className="overflow-hidden rounded-[6px] relative aspect-[16/9]">
          <iframe
            loading={"lazy"}
            title={data?.video?.embed_title}
            src={data?.video?.embed_url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            name="fitvid2"
            className="w-full h-full"
          ></iframe>
        </div>
      </div>
    </section>
  );
}
