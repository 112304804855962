import Image from "next/image";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface ContentGridMagazineData {
  heading?: {
    text?: string;
    title?: string;
  };
  content_collection?: {
    id?: string;
    site_content_collections_id: {
      collection: {
        item: {
          text?: string;
          title?: string;
          alt?: string;
          image_file: {
            image: {
              id?: string;
              filename_disk?: string;
            };
          };
        };
      }[];
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",
  "content_collection.id",
  "content_collection.site_content_collections_id.collection.item:images.alt",
  "content_collection.site_content_collections_id.collection.item:images.image_file.image.id",
  "content_collection.site_content_collections_id.collection.item:images.image_file.image.filename_download",
  "content_collection.site_content_collections_id.collection.item:site_content.text",
] as const;

export default function ContentGridMagazine({
  data,
}: {
  data: ContentGridMagazineData;
}) {
  return (
    <section id="content-grid-magazine" className="w-full py-16 bg-white">
      <div className="max-w-[1080px] mx-auto px-4">
        <h2 className="text-4xl font-display mb-12">{data?.heading?.title}</h2>
        <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
          {data?.content_collection?.map((item) => {
            const imageData = item?.site_content_collections_id?.collection?.[0];
            const contentData = item?.site_content_collections_id?.collection?.[1];
            return (
              <Link
                href="#"
                key={item.id}
                className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:-translate-y-1"
              >
                {imageData && (
                  <div className="relative w-full aspect-[4/3]">
                    <Image
                      src={getReadableImageUrl(imageData.item.image_file.image.id || '', imageData.item.image_file.image.filename_download || '')}
                      alt={imageData.item.alt || ''}
                      fill
                      className="object-cover"
                      quality={95}
                      loading={"lazy"}
                    />
                  </div>
                )}
                <div className="p-4">
                  <h3 className="font-display text-lg mb-2 leading-tight">
                    {contentData?.item?.title || ''}
                  </h3>
                  <div 
                    className="text-secondary text-[13px] leading-relaxed"
                    dangerouslySetInnerHTML={sanitizeHTML(contentData?.item?.text || '')}
                  />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
}
