import Image from "next/image";
import ConsultationFormComponent from "./ConsultationFormComponent";

interface ConsultationFormProps {
  data?: any;
}

export default function ConsultationForm({ data }: ConsultationFormProps) {
  return (
    <section id="consultation-form" className="bg-white py-12 md:py-16">
      <div className="container">
        <div className="flex flex-col md:flex-row md:items-start md:space-x-8 max-w-[1080px] mx-auto">
          <div className="w-full md:w-1/2">
            <div className="relative aspect-[900/749] w-full">
              <Image
                src="/images/compressed_image-32-1.webp"
                alt="Dr Julian De Silva - top facelift surgeons uk"
                fill
                className="object-cover"
                sizes="(max-width: 768px) 100vw, 50vw"
                quality={95}
                loading={"lazy"}
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <h2 className="text-2xl md:text-3xl font-bold mb-6">
              Request a Consultation
            </h2>
            <ConsultationFormComponent />
          </div>
        </div>
      </div>
    </section>
  );
}
