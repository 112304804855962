'use client'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { formValidation } from '@/lib/formValidation'

type FormData = {
  name: string
  email: string
  phone: string
  message: string
  city: string // Honeypot field
}

export default function ConsultationFormComponent() {
  const [isLoading, setIsLoading] = useState(false)
  const [formError, setFormError] = useState<string>('')
  const [formSuccess, setFormSuccess] = useState<string>('')
  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
    mode: 'onBlur',
  })

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)
    setFormError('')
    setFormSuccess('')
    
    try {

        const response = await fetch('/api/send-consultation/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        })

        if (!response.ok) throw new Error()

        setFormSuccess('Thank you for your message! We will get back to you as soon as possible.')
        reset()
    } catch (error) {
      setFormError('Failed to send message. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {formError && (
        <div className="p-3 text-sm text-red-500 bg-red-50 rounded-md">
          {formError}
        </div>
      )}
      {formSuccess && (
        <div className="p-3 text-sm text-green-500 bg-green-50 rounded-md">
          {formSuccess}
        </div>
      )}
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Name <span className="text-red-500">*</span>
        </label>
        <input
          {...register('name', {
            required: 'Name is required',
            minLength: { value: 2, message: 'Name must be at least 2 characters' },
            maxLength: { value: 50, message: 'Name must be less than 50 characters' },
            pattern: {
              value: /^[a-zA-Z\s]*$/,
              message: 'Name can only contain letters and spaces'
            }
          })}
          type="text"
          id="name"
          name="name"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        />
        {errors.name && (
          <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Email <span className="text-red-500">*</span>
        </label>
        <input
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Please enter a valid email address'
            },
            maxLength: { value: 100, message: 'Email must be less than 100 characters' }
          })}
          type="email"
          id="email"
          name="email"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        />
        {errors.email && (
          <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="phone"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Phone No. <span className="text-red-500">*</span>
        </label>
        <input
          {...register('phone', {
            required: 'Phone number is required',
            pattern: {
              value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
              message: 'Please enter a valid phone number'
            },
            minLength: { value: 10, message: 'Phone number must be at least 10 digits' },
            maxLength: { value: 15, message: 'Phone number must be less than 15 digits' }
          })}
          type="tel"
          id="phone"
          name="phone"
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        />
        {errors.phone && (
          <p className="mt-1 text-sm text-red-500">{errors.phone.message}</p>
        )}
      </div>
      <div>
        <label
          htmlFor="message"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Message
        </label>
        <textarea
          {...register('message', {
            maxLength: { value: 500, message: 'Message must be less than 500 characters' }
          })}
          id="message"
          name="message"
          rows={4}
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        ></textarea>
        {errors.message && (
          <p className="mt-1 text-sm text-red-500">{errors.message.message}</p>
        )}
      </div>
      <div className="hidden">
        <input
          {...register('city')}
          type="text"
          id="city"
          autoComplete="off"
        />
      </div>
      <div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full md:w-auto px-8 py-3 bg-black text-white font-medium rounded-md hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? 'Sending...' : 'Submit'}
        </button>
      </div>
    </form>
  )
}
