import Link from "@/components/ui/Link";
import Button from "@/components/ui/button";
import BACarousel from "@/components/ui/BACarousel";
import { sanitizeHTML } from "@/lib/utils";

interface Images {
  images_id: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

interface ConsultationCTAData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  button?: {
    label?: string;
    href?: string;
  };
  images?: Images[];
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text", 
  "subheading.title",

  "text.text",

  "button.label",
  "button.href",

  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",

] as const;

export default function ConsultationCTA({ data }: { data?: ConsultationCTAData }) {
  return (
    <section
      id="ConsultationCTA"
      className="bg-[rgba(177,163,164,0.15)] py-0 pb-[21px] mt-0 mb-0"
    >
      <div className="w-full max-w-[1316px] mx-auto pt-[75px]">
        <div className="text-center">
          <h4 className="font-aileron font-bold uppercase text-[13px] text-black tracking-[2px] mb-[18px]">
            {data?.subheading?.title}
          </h4>
          <h2 className="font-freightBig text-[44px] text-black leading-[1.1em] mb-0 md:text-[50px] sm:text-[30px]">
            {data?.heading?.title}
          </h2>
          <p 
            className="font-aileron text-[16px] leading-[1.5em] text-black pt-[15px] pb-0 mt-0 mb-0"
            dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
          />

          <Button asChild variant="outline" className="mt-4 mb-12">
            <Link href={data?.button?.href || "/"}>
              {data?.button?.label}
            </Link>
          </Button>
          
          <BACarousel images={data?.images || []} />
        </div>
      </div>
    </section>
  );
}
